const Project = () => import('@/views/project/list')
const ProjectInput = () => import('@/views/project/input')
const ProjectEnter = () => import('@/views/project/enter')


// 不需要鉴权
const constant = [
  { path: '/', redirect: '/project' },
  { path: '/project', component: Project },
  { path: '/project/enter', component: ProjectEnter },
  { path: '/project/input', component: ProjectInput },
]


export default [
  ...constant
]